<!-- CA账号信息 -->
<template>
  <div class="csaccount">
    <div
      style="height: 40px;background: #039EEE;font-size: 16px;font-family: SimHei;font-weight: bold;color: #FFFFFF;line-height: 40px;"
    >
      <img
        src="../assets/订单 (1)@2x.png"
        style="height: 17px; width: 17px;margin-left: 10px;vertical-align: sub;"
        alt
      />
      账号信息
    </div>
    <!-- ca用户账号信息 -->
    <el-card shadow="always" v-if="this.type">
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item label="企业名称：" style="margin-top: 80px;">
          <el-input v-model="form.name" disabled style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="CA证书名称：" style="margin-top: 100px;">
          <el-input v-model="form.name" disabled style="width: 80%"></el-input>
        </el-form-item>
        <div style="margin-top: 180px;margin-left: 50px;">
          <img
            src="../assets/i@2x.png"
            style="width: 23px; height: 23px;vertical-align: bottom;"
            alt
          />
          &nbsp;
          如需修改密码请前往CA助手修改
        </div>
      </el-form>
    </el-card>

    <!-- 非ca用户账号信息 -->
    <el-card shadow="always" v-if="this.type == false">
      <el-form ref="form" :model="form" label-width="150px">
        <el-form-item label="登录账号：" style="margin-top: 80px;">
          <el-input v-model="form.account" disabled style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" style="margin-top: 100px;">
          <el-button type="primary" @click="changePhone = true">更改手机号</el-button>
        </el-form-item>
        <el-form-item label="密码：" style="margin-top: 100px;">
          <el-button type="primary" @click="changePassword = true">更改密码</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-dialog title="更改手机号" :visible.sync="changePhone" width="30%" @closed="closed">
      <div style="text-align: center;">当前手机号: {{this.phone}}</div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        style="margin-top: 30px;"
      >
        <el-form-item label="当前手机号" prop="phone">
          <span class="befor_1">*</span>
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="新手机号" prop="newPhone">
          <span class="befor_2">*</span>
          <el-input v-model="ruleForm.newPhone"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input v-model="ruleForm.code" style="width: 73%;"></el-input>
          <el-button
            type="primary"
            style="margin-left: 15px; width: 100px"
            @click="sendMsg"
            :disabled="isDisabled1"
          >{{ buttonName1 }}</el-button>
        </el-form-item>
        <el-form-item style="text-align: center;margin-right: 80px;">
          <el-button type="primary" @click="submitPhone('ruleForm')">确认修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="更改密码" :visible.sync="changePassword" width="30%" @closed="passwordclosed">
      <div style="text-align: center;">当前手机号: {{this.phone}}</div>
      <el-form
        :model="passwordForm"
        :rules="passwordrules"
        ref="passwordForm"
        label-width="100px"
        style="margin-top: 30px;"
      >
        <el-form-item label="新密码" prop="password">
          <el-input v-model="passwordForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="newPassword">
          <span class="befor_3">*</span>
          <el-input v-model="passwordForm.newPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <el-input v-model="passwordForm.code" style="width: 73%;"></el-input>
          <el-button
            type="primary"
            style="margin-left: 15px; width: 100px"
            @click="sendMsgTwo"
            :disabled="isDisabled2"
          >{{ buttonName2 }}</el-button>
        </el-form-item>
        <el-form-item style="text-align: center;margin-right: 80px;">
          <el-button type="primary" @click="submitPassword('passwordForm')">确认修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
//import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},

  // 组件名称
  name: "",

  // 这里存放数据
  data() {
    const phoneRules = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      } else {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passwordForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      form: {
        name: window.sessionStorage.getItem("firmName"),
        account: window.sessionStorage.getItem("username"),
      },
      changePhone: false,
      ruleForm: {
        phone: "",
        newPhone: "",
        code: "",
      },
      rules: {
        phone: [{ validator: phoneRules, trigger: "blur" }],
        newPhone: [{ validator: phoneRules, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      buttonName1: "立即获取",
      buttonName2: "立即获取",
      isDisabled1: false,
      isDisabled2: false,
      time: 59,
      changePassword: false,
      passwordForm: {
        password: "",
        newPassword: "",
        code: "",
      },
      passwordrules: {
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "6~20位，包含大、小写字母，数字至少各1个",
          },
          {
            pattern:
              /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_]+$)(?![a-z0-9]+$)(?![a-z\W_]+$)(?![0-9\W_]+$)[a-zA-Z0-9]{6,20}$/,
            message: "6~20位，包含大、小写字母，数字至少各1个",
            trigger: "blur",
          },
        ],
        newPassword: [{ validator: validatePass, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      type: false,
      phone: "",
    };
  },

  // 监听属性 类似于data概念
  computed: {},

  // 监控data中的数据变化
  watch: {},

  // 方法集合
  methods: {
    sendMsg() {
      this.$refs.ruleForm.validateField("newPhone", (errorMsg) => {
        if (!errorMsg) {
          this.axios({
            method: "POST",
            url: "/registerController.do?getHandsetRandCode",
            data: {
              handsetNum: this.ruleForm.newPhone,
              isCheck: 2,
              fromType:10,
            },
          }).then((res) => {
            if (res.data.status == 0) {
              let me = this;
              me.isDisabled1 = true;
              let interval = window.setInterval(function () {
                me.buttonName1 = me.time;
                --me.time;
                if (me.time < 0) {
                  me.buttonName1 = "重新发送";
                  me.time = 59;
                  me.isDisabled1 = false;
                  window.clearInterval(interval);
                }
              }, 1000);
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
        }
      });
    },

    sendMsgTwo() {
      this.$refs.passwordForm.validateField("newPassword", (errorMsg) => {
        if (!errorMsg) {
          this.axios({
            method: "POST",
            url: "/registerController.do?getHandsetRandCode",
            data: {
              handsetNum: window.sessionStorage.getItem("phone"),
            },
          }).then((res) => {
            if (res.data.status == 0) {
              let me = this;
              me.isDisabled2 = true;
              let interval = window.setInterval(function () {
                me.buttonName2 = me.time;
                --me.time;
                if (me.time < 0) {
                  me.buttonName2 = "重新发送";
                  me.time = 59;
                  me.isDisabled2 = false;
                  window.clearInterval(interval);
                }
              }, 1000);
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
        }
      });
    },

    submitPhone(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios({
            method: "POST",
            url: "/registerController.do?updateMobiles",
            data: {
              username: window.sessionStorage.getItem("username"),
              handsetNum: this.ruleForm.phone,
              newHandsetNum: this.ruleForm.newPhone,
              randCode: this.ruleForm.code,
              fromType:10,
            },
          }).then((res) => {
            if (res.data.status == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.changePhone = false;
              window.sessionStorage.setItem("phone", this.ruleForm.newPhone);
              this.phone = this.ruleForm.newPhone.substring(0, 3) + "****" + this.ruleForm.newPhone.substring(7);
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    submitPassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.axios({
            method: "POST",
            url: "/registerController.do?updatePassword",
            data: {
              handsetNum: window.sessionStorage.getItem("phone"),
              password: this.passwordForm.password,
              confirmPassword: this.passwordForm.newPassword,
              randCode: this.passwordForm.code,
              fromType:10,
            },
          }).then((res) => {
            if (res.data.status == 0) {
              this.$message({
                message: "修改成功",
                type: "success",
              });
              this.changePassword = false;
            } else {
              this.$message({
                message: res.data.statusInfo,
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },

    closed() {
      this.$refs.ruleForm.clearValidate();
      this.ruleForm.phone = "";
      this.ruleForm.newPhone = "";
      this.ruleForm.code = "";
    },

    passwordclosed() {
      this.$refs.passwordForm.clearValidate();
      this.passwordForm.password = "";
      this.passwordForm.newPassword = "";
      this.passwordForm.code = "";
    },

    getEnterpriseMessage() {
      var type = window.sessionStorage.getItem("type");
      if (type == "CA") {
        var params = {
          CAKeyId: window.sessionStorage.getItem("firmName"),
          from: 10,
          CAKeyEncStr: window.sessionStorage.getItem("date"),
        };
      } else if (type == "CFCA") {
        var params = {
          CAKeyId: window.sessionStorage.getItem("firmName"),
          from: 10,
          CAKeyEncStr: window.sessionStorage.getItem("content"),
        };
      } else {
        var params = {
          handsetNum: window.sessionStorage.getItem("phone"),
          from: 10,
        };
      }
      this.axios({
        method: "GET",
        url: "/newGuarantee.do?existEnterpriseInfo",
        params: params,
      }).then((res) => {
        if (res.data.data.userStatus == '1' || (res.data.data.userStatus == '3' && !res.data.data.apply)) {
          this.$confirm("企业信息未完善,请先完善企业信息", "提示", {
            confirmButtonText: "确定",
            closeOnPressEscape: false,
            closeOnClickModal: false,
            closeOnHashChange: false,
            showCancelButton: false,
            showClose: false,
            type: "warning",
          }).then(() => {
            if (type == "account") {
              this.$router.push("/enterprise");
            } else {
              this.$router.push("/message");
            }
          });
        } else if(res.data.data.userStatus == '3' && res.data.data.apply){
          this.$confirm(res.data.data.errorInfo+"，请先完善企业信息", "提示", {
            confirmButtonText: "确定",
            closeOnPressEscape: false,
            closeOnClickModal: false,
            closeOnHashChange: false,
            showCancelButton: false,
            showClose: false,
            type: "warning",
          }).then(() => {
            if (type == "account") {
              this.$router.push("/enterprise");
            } else {
              this.$router.push("/message");
            }
          });
        }
      });
    },
  },

  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},

  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getEnterpriseMessage();
    if (window.sessionStorage.getItem("type") == "account") {
      var phone = window.sessionStorage.getItem("phone");
      this.phone = phone.substring(0, 3) + "****" + phone.substring(7);
      this.type = false;
    } else {
      this.type = true;
    }
  },

  // 生命周期 - 创建之前
  beforeCreate() {},

  // 生命周期 - 挂载之前
  beforeMount() {},

  // 生命周期 - 更新之前
  beforeUpdate() {},

  // 生命周期 - 更新之后
  updated() {},

  // 生命周期 - 销毁之前
  beforeDestroy() {},

  // 生命周期 - 销毁完成
  destroyed() {},

  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},

  // keep-alive缓存离开之后触发
  deactivated() {},
};
</script>

<style scoped>
.csaccount {
  height: 100%;
  background-color: #fff;
}
.el-card {
  width: 821px;
  height: 548px;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 95px;
}
.befor_1 {
  position: absolute;
  left: -93px;
  color: #f56c6c;
}
.befor_2 {
  position: absolute;
  left: -80px;
  color: #f56c6c;
}
.befor_3 {
  position: absolute;
  left: -93px;
  color: #f56c6c;
}
</style>